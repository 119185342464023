<template>
  <v-dialog v-model="dialogEditFile" width="600px">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          {{
            $tc("titleVue.espaceCuisine.dialogEdit", 1, {
              name: fileInfo.fileName
            })
          }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click.native="dialogEditFile = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="validForm">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formData.code"
                :label="$t('form.espaceCuisine.dialogEdit.code')"
                :rules="[rules.numeric, rules.required]"
                typer="number"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.libelle"
                :label="$t('form.espaceCuisine.dialogEdit.name')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogEditFile = false" color="error">
          {{ $t("button.close") }}
        </v-btn>
        <v-btn @click="sendForm" :disabled="!validForm" color="info">
          {{ $t("button.validate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "dialogEdit",
  created() {
    this.getUserInfo().then(res => {
      this.userDroit = this.userInfo["droit"];
    });
    this.formData.code = this.fileInfo.code;
    this.formData.libelle = this.fileInfo.libelleFile;
  },

  props: {
    dialog: { type: Boolean, required: true },
    fileInfo: { type: Object, required: true },
    isUpload: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      validForm: false,
      rules: {
        numeric: v => Number.isInteger(v * 1) || this.$t("rules.numericOnly"),
        required: v => !!v || this.$t("rules.required")
      },
      formData: {
        code: "",
        libelle: ""
      }
    };
  },

  computed: {
    ...mapState("user", ["userInfo"]),
    dialogEditFile: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close", val);
      }
    }
  },

  methods: {
    ...mapActions("espaceCuisine", ["updateFile"]),
    ...mapActions("user", ["getUserInfo"]),
    sendForm() {
      this.$root.$overlay.show();
      if (this.isUpload) {
        this.$emit("file-upload", this.formData);
        this.$root.$overlay.hide();
      } else {
        this.updateFile({
          fichierId: this.fileInfo.id,
          libelle: this.formData.libelle,
          code: this.formData.code
        })
          .then(res => {
            this.$root.$snackBar(this.$t("success.editFileCuisine"));
            this.$root.$overlay.hide();
            this.$emit("file-download", this.fileInfo.id);
          })
          .catch(res => {
            this.$root.$snackBar(this.$t("errors.editFileCuisine"), {
              color: "error"
            });
            this.$root.$overlay.hide();
          });
      }
    }
  }
};
</script>
<style scoped>
.v-form .row .col {
  padding: 0px !important;
}
</style>
