<template>
  <div id="file-drag-drop">
    <v-row>
      <form ref="fileform">
        <!-- Titre -->
        <v-col cols="12">
          <v-row>
            <v-col cols="4">
              <v-select
                :label="$t('form.espaceCuisine.kitchen')"
                :items="listCuisine"
                v-model="cuisineId"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>

        <!-- Tableau -->
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="fichiers"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': $t('linePage')
            }"
            v-model="selected"
            item-key="id"
            show-select
            class="elevation-1"
            fixed-header
            :height="`${heightTable}px`"
          >
            <template slot="pageText" slot-scope="props">
              {{
                $t("lineOf", {
                  pageStart: props.pageStart,
                  pageStop: props.pageStop,
                  itemsLength: props.itemsLength
                })
              }}
            </template>
            <template slot="no-data">
              <v-alert :value="true" color="error" icon="mdi-alert-outline">
                {{ $t("noData") }}
              </v-alert>
            </template>
            <template #[`item.action`]="{ item }">
              <v-btn
                small
                icon
                fab
                class="mx-1 blue--text"
                :title="$t('button.download')"
                @click.native="openDialogEdit(item)"
              >
                <v-icon>mdi-cloud-download-outline</v-icon>
              </v-btn>
              <v-btn
                small
                icon
                fab
                class="mx-1 red--text"
                v-if="userDroit >= 3"
                :title="$t('button.delete')"
                @click.native="deleteFiles(item.id)"
              >
                <v-icon>mdi-delete-forever-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="auto">
          <v-btn color="error" v-if="userDroit >= 3" @click="deleteFiles()">
            {{ $t("button.deleteAll") }}
          </v-btn>
        </v-col>
        <div class="text-xs-center">
          <v-dialog v-model="dialog" value="20" persistent width="300">
            <v-card color="secondary" dark>
              <v-card-text>
                {{ $t("sendProgress") }}
                <v-progress-linear
                  v-model="uploadPercentage"
                  color="success"
                  max="100"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </form>
    </v-row>
    <DialogFTEdit
      v-if="dialogEdit"
      :dialog.sync="dialogEdit"
      :fileInfo="fileInfo"
      :isUpload="isUpload"
      @close="closeDialogEdit"
      @file-download="exporter"
      @file-upload="sendFile"
    />
  </div>
</template>

<script>
import DialogFTEdit from "@/components/EspaceCuisineEditDialog";
import { APIError, getURLApi } from "../utils";
import axios from "axios";
const cfc = axios.create({
  baseURL: getURLApi() + `cfcs`
});
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "espaceCuisine",
  created() {
    this.loading = true;
    this.fetchListCuisine()
      .then(data => {
        this.loading = false;
      })
      .catch(res => {
        this.loading = false;
        this.$root.$snackBar(this.$t("errors.kitchenList"), {
          color: "error"
        });
      });
    this.getUserInfo().then(red => {
      this.userDroit = this.userInfo["droit"];
    });
  },
  data() {
    return {
      dialogEdit: false,
      fileInfo: null,
      isUpload: false,
      userDroit: 0,
      //Drag and drop
      dragAndDropCapable: false,
      files: [],
      uploadPercentage: 0,
      dialog: false,
      //Donnée Data table
      rowsPerPageItems: [50],
      pagination: {
        sortBy: "fileName"
      },
      loading: false,
      selected: [],
      headers: [
        {
          text: this.$t("headers.espaceCuisine.espaceCuisine.fileName"),
          align: "left",
          value: "fileName"
        },
        {
          text: this.$t("headers.espaceCuisine.espaceCuisine.name"),
          align: "left",
          value: "libelleFile"
        },
        {
          text: this.$t("headers.espaceCuisine.espaceCuisine.addBy"),
          value: "login"
        },
        {
          text: this.$t("headers.espaceCuisine.espaceCuisine.addThe"),
          value: "dateCreate"
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
          width: "160px",
          class: "min-width-action"
        }
      ]
    };
  },

  computed: {
    ...mapState("espaceCuisine", [
      "fichiers",
      "listCuisine",
      "selectedCuisine"
    ]),
    ...mapState("user", ["userInfo", "token"]),
    heightTable() {
      return parseInt(window.innerHeight * 0.55);
    },
    cuisineId: {
      get() {
        return this.selectedCuisine;
      },
      set(value) {
        this.setSelectCuisine(value);
        this.loading = true;
        this.getFile();
      }
    }
  },

  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    if (this.dragAndDropCapable) {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop"
      ].forEach(
        function(evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );
      this.$refs.fileform.addEventListener(
        "drop",
        function(e) {
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            this.files.push(e.dataTransfer.files[i]);
          }
          this.openDialogEdit({ fileName: this.files[0].name }, true);
        }.bind(this)
      );
    }
  },

  methods: {
    ...mapActions("espaceCuisine", [
      "fetchFile",
      "deleteFile",
      "fetchListCuisine",
      "getFileExist"
    ]),
    ...mapActions("user", ["getUserInfo"]),
    ...mapMutations("espaceCuisine", ["setSelectCuisine"]),
    deleteFiles(id) {
      if (id == undefined) id = this.selected.map(r => r.id);
      else id = [id];

      this.$root
        .$confirm(
          this.$t("button.delete"),
          this.$tc("sureFile", id.length, {
            count: id.length
          }),
          {
            color: "error"
          }
        )
        .then(confirm => {
          if (confirm) {
            this.deleteFile({ listDelete: id.join() })
              .then(data => {
                this.$root.$snackBar(this.$t("success.delete"));
                this.selected = [];
                this.getFile();
              })
              .catch(res => {
                this.$root.$snackBar(this.$t("errors.delete"), {
                  color: "error"
                });
              });
          }
        });
    },
    openDialogEdit(file, isUpload = false) {
      this.fileInfo = file;
      this.isUpload = isUpload;
      this.dialogEdit = true;
    },
    closeDialogEdit() {
      this.fileInfo = null;
      this.isUpload = false;
      this.dialogEdit = false;
      this.getFile();
    },
    exporter(id) {
      this.closeDialogEdit();
      this.getFileExist({ fileId: id }).then(res => {
        if (res) {
          window.open(getURLApi() + "getFileCuisine.cfm?fileId=" + id);
        } else {
          this.$root.$snackBar(this.$t("errors.kitchenFile"), {
            color: "error"
          });
        }
      });
    },
    sendFile(form) {
      this.closeDialogEdit();
      this.submitFiles(form);
    },
    getFile() {
      this.loading = true;
      this.fetchFile({ cuisineId: this.cuisineId })
        .then(data => {
          this.loading = false;
        })
        .catch(res => {
          this.loading = false;
          this.$root.$snackBar(this.$t("errors.kitchenFiles"), {
            color: "error"
          });
        });
    },
    /*
      Fonction upload files
    */
    determineDragAndDropCapable() {
      var div = document.createElement("div");

      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    submitFiles(form = {}) {
      if (this.userDroit >= 3) {
        let formData = new FormData();
        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];

          formData.append("files[" + i + "]", file);
        }
        this.dialog = true;
        return cfc
          .post("/espaceCuisine.cfc", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: this.token
            },
            params: {
              method: "uploadFiles",
              codeSiteId: this.selectedCuisine,
              code: form.code,
              libelle: form.libelle
            },
            onUploadProgress: function(progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }.bind(this)
          })
          .then(data => {
            this.$root.$snackBar(this.$t("success.upload"));
            this.getFile();
          })
          .catch(res => {
            this.$root.$snackBar(this.$t("errors.upload"), {
              color: "error"
            });
          })
          .finally(() => {
            this.dialog = false;
            this.files = [];
          });
      } else {
        this.dialog = false;
        this.$root.$snackBar(this.$t("errors.law"), {
          color: "error"
        });
      }
    }
  },
  components: { DialogFTEdit }
};
</script>

<style scoped>
.min-width-action {
  min-width: 160px !important;
  max-width: 160px !important;
}
form {
  display: block;
  min-height: 100%;
  width: 100%;
  background: transparent;
}
</style>
